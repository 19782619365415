.Menu {
  &.fluid .bubble {
    min-width: 13.5rem;
    width: auto;
  }

  .backdrop {
    position: fixed;
    left: -100vw;
    right: -100vw;
    top: -100vh;
    bottom: -100vh;
    z-index: var(--z-menu-backdrop);
  }

  .bubble {
    overflow: hidden;
    display: block;
    list-style: none;
    padding: 0.5rem 0;
    margin: 0;
    position: absolute;
    background-color: var(--color-background);
    box-shadow: 0 0.25rem 0.5rem 0.125rem var(--color-default-shadow);
    border-radius: var(--border-radius-default);
    min-width: 13.5rem;
    z-index: var(--z-menu-bubble);
    overscroll-behavior: contain;

    transform: scale(0.5);
    transition: opacity 0.2s cubic-bezier(0.2, 0, 0.2, 1), transform 0.2s cubic-bezier(0.2, 0, 0.2, 1) !important;

    &.open {
      transform: scale(1);
    }

    &.closing {
      transition: opacity 0.2s ease-in, transform 0.2s ease-in !important;
    }

    body.animation-level-0 & {
      transform: none !important;
      transition: opacity 0.15s !important;
    }

    --offset-y: calc(100% + 0.5rem);
    --offset-x: 0;

    &.top {
      top: var(--offset-y);
    }

    &.bottom {
      bottom: var(--offset-y);
    }

    &.left {
      left: var(--offset-x);
    }

    &.right {
      right: var(--offset-x);
    }

    &.with-footer {
      padding-bottom: 0;
    }
  }

  body.has-open-dialog &:not(.CustomSendMenu):not(.web-app-more-menu):not(.attachment-modal-more-menu) .bubble {
    transition: none !important;
  }

  .footer {
    padding: 0.5rem 0;
    background: var(--color-chat-hover);
    color: var(--color-text-secondary);
    font-size: 0.8125rem;
    text-align: center;
  }

  &.compact {
    .bubble {
      background: var(--color-background-compact-menu);
      backdrop-filter: blur(10px);
      padding: 0.25rem 0;
    }

    .footer {
      background: none;
    }

    &.no-blur .bubble {
      background: var(--color-background);
    }
  }

  .menu-loading-row {
    margin: 0.125rem 1rem;
    width: calc(100% - 2rem);
  }
}
